@import url("https://fonts.googleapis.com/css2?family=Comic+Sans+MS&display=swap");

.App {
  background-color: #eeeaf6;
  font-family: "Comic Sans MS", sans-serif;
}

.navbar {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-link.btn.btn-link {
  color: #ececec;
  text-decoration: none;
}

.nav-link.btn.btn-link:hover {
  color: #ececec;
  font-weight: bold;
}

.nav-link.btn.btn-link:focus {
  box-shadow: none;
}

.navbar-collapse.show {
  background-color: white;
}

.navbar-collapse.show .nav-link.btn.btn-link {
  color: black;
}
